<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'teams-index'}">Teams</router-link>
				</li>
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'teams-new'}">New team</router-link>
				</li>
			</ol>
		</nav>

		<h3>New team</h3>

		<form @submit.prevent="save">
			<div class="form-group">
				<h5>Team name</h5>
				<input v-model="model.name" type="text" class="form-control" placeholder="Team A, Team B, Partner 1">
			</div>

			<button class="btn btn-primary px-4" type="button" :disabled="loading">
				<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-if="loading"></span>
				<button type="submit" class="btn btn-primary px-4">SAVE</button>
			</button>
		</form>
	</div>
</template>

<script>
	import api from '@/api'

	export default {
		data() {
			return {
				loading: false,
				model: {
					name: ""
				}
			}
		},
		methods: {
			async save() {
				this.loading = true
				let team = await api.Teams.save(this.model).finally(() => this.loading = false)
				this.$router.push({name: 'teams-edit-uuid', params: {uuid: team.Id}})
			}
		}
	}
</script>
